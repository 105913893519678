import { ILocation } from "../types";

export const MAP_COORDINATES: {[key: string]: ILocation } = {
    start: {lat: 46.496389 , lng: 11.358056},
    stationCommunication: {lat: 46.498333, lng:11.350556},
    stationRelationship: {lat: 46.502778, lng: 11.345556},
    station4Fashion: {lat: 46.49722, lng: 11.348611},
    station4Geek: {lat: 0, lng: 0},
    station4Music: {lat: 46.495278, lng: 11.353611},
    station4Sport: {lat: 46.503611, lng: 11.348889},
    stationEnd: {lat: 46.493333, lng: 11.351944},
}

export const googleMapsApiKey = 'AIzaSyDYr6og90erYaDg44db2OJ82-hbRZTVF7o'; // Replace with your Google Maps API Key
