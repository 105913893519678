import React, { Suspense, useCallback, useEffect, useRef } from 'react';

// eslint-disable-next-line
import "swiper/css/bundle";
import './Swiper.css';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import './App.css';
import './App.characterResolution.css'
import './App.form.css';
import './index.css';

import {APIProvider} from '@vis.gl/react-google-maps';
import { googleMapsApiKey } from './utils/constants';
import { LoadScript } from '@react-google-maps/api';

export const routes = { //Will be use for auth
  index: '/',
  sponsors: '/sponsors',
  language: '/language',
  province: '/province',
  instructions: '/instructions',
  city: '/city',
  character: '/character',
  character_custom: '/character/custom',
  station: {
    station1A: '/s/station1/A',
    station1B: '/s/station1/B',
    communicationA: '/s/communication/A',
    communicationB: '/s/communication/B',
    relationshipA: '/s/relationship/A',
    relationshipB: '/s/relationship/B',
    musicA: '/s/4music/A',
    musicB: '/s/4music/B',
    fashionA: '/s/4fashion/A',
    fashionB: '/s/4fashion/B',
    sportA: '/s/4sport/A',
    sportB: '/s/4sport/B',
    geekA: '/s/4geek/A',
    geekB: '/s/4geek/B',
    endA: '/s/end/A',
    endB: '/s/end/B',
    endC: '/s/end/C',
    endD: '/s/end/D',
  }
}

const SCREENS = {
  Welcome:                React.lazy(()=> import(/* webpackChunkName: welcome */'./pages/Welcome')),
  Sponsors:               React.lazy(()=> import(/* webpackChunkName: sponsors */'./pages/CattownSponsors')),
  SelectLanguage:         React.lazy(()=> import(/* webpackChunkName: language*/ './pages/SelectLanguage')),
  ProvinceMessage:        React.lazy(()=> import(/* webpackChunkName: province */ './pages/ProvinceMessage')),
  Instructions:           React.lazy(()=> import(/* webpackChunkName: instructions*/ './pages/Instrusctions')),
  SelectCity:             React.lazy(()=> import(/* webpackChunkName: select-city */ './pages/SelectCity')),
  SelectCharacter:        React.lazy(()=> import(/* webpackChunkName: select-character */ './pages/SelectCharacter')),
  CharacterCustom:        React.lazy(()=> import(/* webpackChunkName: custom-character */ './pages/CharacterCustom')),
  Station1A:              React.lazy(()=> import(/* webpackChunkName: s-1-a */ './pages/Station1A')),
  Station1B:              React.lazy(()=> import(/* webpackChunkName: s-1-a */ './pages/Station1B')),
  StationCommunicationA:  React.lazy(()=> import(/* webpackChunkName: s-c-a */ './pages/StationCommunicationA')),
  StationCommunicationB:  React.lazy(()=> import(/* webpackChunkName: s-c-b */ './pages/StationCommunicationB')),
  StationRelationshipA:   React.lazy(()=> import(/* webpackChunkName: s-r-a */ './pages/StationRelationshipA')),
  StationRelationshipB:   React.lazy(()=> import(/* webpackChunkName: s-r-b */ './pages/StationRelationshipB')),
  StationMusicA:          React.lazy(()=> import(/* webpackChunkName: s-m-a */ './pages/Station4MusicA')),
  StationMusicB:          React.lazy(()=> import(/* webpackChunkName: s-m-b */ './pages/Station4MusicB')),
  StationFashionA:        React.lazy(()=> import(/* webpackChunkName: s-f-a */ './pages/Station4FashionA')),
  StationFashionB:        React.lazy(()=> import(/* webpackChunkName: s-f-b */ './pages/Station4FashionB')),
  StationSportA:          React.lazy(()=> import(/* webpackChunkName: s-s-a */ './pages/Station4SportA')),
  StationSportB:          React.lazy(()=> import(/* webpackChunkName: s-s-b */ './pages/Station4SportB')),
  StationGeekA:           React.lazy(()=> import(/* webpackChunkName: s-g-a */ './pages/Station4GeekA')),
  StationGeekB:           React.lazy(()=> import(/* webpackChunkName: s-g-b */ './pages/Station4GeekB')),
  StationEndA:            React.lazy(()=> import(/* webpackChunkName: s-e-a */ './pages/StationEndA')),
  StationEndB:            React.lazy(()=> import(/* webpackChunkName: s-e-b */ './pages/StationEndB')),
  StationEndC:            React.lazy(()=> import(/* webpackChunkName: s-e-c */ './pages/StationEndC')),
  StationEndD:            React.lazy(()=> import(/* webpackChunkName: s-e-d */ './pages/StationEndD'))
}

function App() {
  const refResize = useRef<HTMLDivElement>(null);

  return (
    <div ref={refResize} className="App row justify-content-center align-items-center">

      <BrowserRouter>
        <Suspense fallback={<div>Loading...</div>}>
        <APIProvider apiKey={googleMapsApiKey}>

        <Routes>
          <Route path={routes.index} index  element={<SCREENS.Welcome />} />
          <Route path={routes.sponsors}     element={<SCREENS.Sponsors />} />
          <Route path={routes.language}     element={<SCREENS.SelectLanguage />} />
          <Route path={routes.province}     element={<SCREENS.ProvinceMessage />} />
          <Route path={routes.instructions} element={<SCREENS.Instructions />} />
          <Route path={routes.city}         element={<SCREENS.SelectCity />} />
          <Route path={routes.character}    element={<SCREENS.SelectCharacter />} />
          <Route path={routes.character_custom} element={<SCREENS.CharacterCustom />} />
          <Route path={routes.station.station1A}   element={<SCREENS.Station1A/>} />
          <Route path={routes.station.station1B}   element={<SCREENS.Station1B/>} />
          <Route path={routes.station.communicationA} element={<SCREENS.StationCommunicationA/>} />
          <Route path={routes.station.communicationB} element={<SCREENS.StationCommunicationB/>} />
          <Route path={routes.station.relationshipA}  element={<SCREENS.StationRelationshipA />} />
          <Route path={routes.station.relationshipB}  element={<SCREENS.StationRelationshipB />} />
          <Route path={routes.station.musicA} element={<SCREENS.StationMusicA/>} />
          <Route path={routes.station.musicB} element={<SCREENS.StationMusicB/>} />
          <Route path={routes.station.fashionA} element={<SCREENS.StationFashionA/>} />
          <Route path={routes.station.fashionB} element={<SCREENS.StationFashionB/>} />
          <Route path={routes.station.sportA}   element={<SCREENS.StationSportA/>} />
          <Route path={routes.station.sportB}   element={<SCREENS.StationSportB/>} />
          <Route path={routes.station.geekA}    element={<SCREENS.StationGeekA/>} />
          <Route path={routes.station.geekB}    element={<SCREENS.StationGeekB/>} />
          <Route path={routes.station.endA}     element={<SCREENS.StationEndA/>} />
          <Route path={routes.station.endB}     element={<SCREENS.StationEndB/>} />
          <Route path={routes.station.endC}     element={<SCREENS.StationEndC/>} />
          <Route path={routes.station.endD}     element={<SCREENS.StationEndD/>} />
        </Routes>
        </APIProvider>
        </Suspense>
      </BrowserRouter>

    </div>
  );
}

export default App;
